import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';
import updateEwayBill from './updateEwayBill';
import invoiceDtls from './invoiceDtls';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../../utility/url.utility';

export default {
  name: 'eWayBillEntryForm',
  props: ['rowData'],
  watch: {},
  components: {
    DatePicker,
    ModelSelect,
    BasicSelect,
    updateEwayBill,
    invoiceDtls
  },
  data() {
    return {
      loader: false,
      selectedTrxType: {
        value: null,
        text: null
      },
      trxTypeOptions: [],
      selectedSupplyType: {
        value: null,
        text: null
      },
      supplyTypeOptions: [],
      selectedSubType: {
        value: null,
        text: null
      },
      subTypeOptions: [],
      docTypeList: [],
      docType: {
        value: null,
        text: null
      },
      docNum: null,
      nameBillFrom: null,
      docDate: null,
      gstinBillFrom: null,
      stateListBillFrom: [],
      selectedStateBillFrom: {
        value: null,
        text: null
      },
      pinDesFrom: null,
      address1DesFrom: null,
      address2DesFrom: null,
      placeDesFrom: null,
      nameBillTo: null,
      gstinBillTo: null,
      stateListBillTo: [],
      selectedStateBillTo: {
        value: null,
        text: null
      },
      address1ShipTo: null,
      address2ShipTo: null,
      placeShipTo: null,
      pinDesTo: null,
      eWayBillItemDtlsData: [
        {
          productName: null,
          productDesc: null,
          hsnCode: null,
          quantity: null,
          qtyUnit: {
            text: null,
            value: null
          },
          cgstRate: null,
          sgstRate: null,
          cessRate: null,
          igstRate: null,
          taxableAmount: null,
          cgst_amt: null,
          sgst_amt: null,
          igst_amt: null,
          cess_amt: null,
          itemLineId: 0
          // total_inv_value: null
        }
      ],
      eWayBillItemDtlsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'productName',
          label: 'Product Name',
          class: 'text-center'
        },
        {
          key: 'productDesc',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'hsnCode',
          label: 'HSN',
          class: 'text-center'
        },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'qtyUnit',
          label: 'UQC',
          class: 'text-center'
        },
        {
          key: 'taxableAmount',
          label: 'Taxable Value(Rs.)',
          class: 'text-center'
        },
        {
          key: 'cgstRate',
          label: 'Tax Rate (CGST)',
          class: 'text-center'
        },
        {
          key: 'sgstRate',
          label: 'Tax Rate (SGST)',
          class: 'text-center'
        },
        {
          key: 'igstRate',
          label: 'Tax Rate (IGST)',
          class: 'text-center'
        },
        {
          key: 'cessRate',
          label: 'Tax Rate (CESS)',
          class: 'text-center'
        },
        // {
        //   key: 'taxableAmount',
        //   label: 'Total Amount',
        //   class: 'text-center'
        // },
        {
          key: 'cgst_amt',
          label: 'CGST Amount',
          class: 'text-center'
        },
        {
          key: 'sgst_amt',
          label: 'SGST Amount',
          class: 'text-center'
        },
        {
          key: 'igst_amt',
          label: 'IGST Amount',
          class: 'text-center'
        },
        {
          key: 'cess_amt',
          label: 'CESS Amount',
          class: 'text-center'
        }
        // {
        //   key: 'total_inv_value',
        //   label: 'Total Inv Value',
        //   class: 'text-center'
        // }
      ],
      transporterName: null,
      transporterId: null,
      transDistance: 0,
      selectedMode: {
        value: null,
        text: null
      },
      modeOptions: [],
      selectedVehicleType: {
        value: null,
        text: null
      },
      vehicleTypeOptions: [],
      vehicleNum: null,
      trnsportDocNum: null,
      trnsportDocDate: null,
      totalAmount: 0,
      cgstAmount: 0,
      sgstAmount: 0,
      igstAmount: 0,
      cessAmount: 0,
      totalInvValue: 0,
      editMode: false,
      eWayBillNum: null,
      updateFormType: null,
      showUpdateEwayBillModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      updateProps: {},
      updateButtonsFlag: false,
      gebButtonFlag: false,
      extendEwayBillFlag: false,
      cancelEwayBillFlag: false,
      gebId: null,
      updatePartBFlag: false,
      ewayBillExpiryDate: null,
      ewbManualErpFlag: false,
      sourceTypeOptions: [],
      sourceType: {
        text: null,
        value: null
      },
      invoiceNumber: null,
      switchButtonFlag: false,
      ewbDtlsData: [],
      ewbDtlsFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'ewbNo',
          label: 'Eway Bill Number',
          class: 'text-center'
        },
        {
          key: 'documentDate',
          label: 'Document Date',
          class: 'text-center'
        },
        {
          key: 'ewbDate',
          label: 'valid From',
          class: 'text-center'
        },
        {
          key: 'ewbValidDate',
          label: 'valid Until',
          class: 'text-center'
        },
        {
          key: 'transporterName',
          label: 'Transporter Name',
          class: 'text-center'
        },
        {
          key: 'transDistance',
          label: 'Distance',
          class: 'text-center'
        },
        {
          key: 'vehicleNo',
          label: 'Vehicle Number',
          class: 'text-center'
        },
        {
          key: 'vehicleTypeMeaning',
          label: 'Vehicle Type',
          class: 'text-center'
        },
        {
          key: 'ewbDescription',
          label: 'Remarks',
          class: 'text-center'
        },
        {
          key: 'ewbStatus',
          label: 'E-Way Bill Status',
          class: 'text-center'
        },
        {
          key: 'cancelDate',
          label: 'Cancel Date',
          class: 'text-center'
        }
      ],
      showInvDtlsModal: false,
      igstFlag: false,
      cgstSgstFlag: false,
      erpFlag: false,
      qtyUnitOptions: [],
      showValueSetModal: false,
      specIndex: null,
      dowloadReport: URL_UTILITY.getDownloadReportUrl,
      reqId: null
    };
  },
  validations: {
    selectedSupplyType: {
      text: { required }
    },
    selectedTrxType: {
      text: { required }
    },
    selectedSubType: {
      text: { required }
    },
    docType: {
      text: { required }
    },
    docNum: { required },
    docDate: { required },
    gstinBillFrom: { required },
    gstinBillTo: { required },
    pinDesFrom: { required },
    pinDesTo: { required },
    transDistance: { required }
  },
  mounted() {
    this.getValueSetDtls();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'save' &&
          !this.showUpdateEwayBillModal &&
          !this.eWayBillNum
        ) {
          // this.editMode = false;
          this.saveEwayBill();
        }
        if (actionName === 'update' && !this.showUpdateEwayBillModal) {
          this.editMode = true;
        }
        if (
          actionName === 'download' &&
          this.ewbDtlsData[0].ewbStatus !== 'cancelled' &&
          !this.showUpdateEwayBillModal
        ) {
          this.downloadLog();
        }
        if (
          actionName === 'print' &&
          this.ewbDtlsData[0].ewbStatus !== 'cancelled' &&
          !this.showUpdateEwayBillModal &&
          this.eWayBillNum
        ) {
          this.printReport();
        }
      }
    });
    if (this.rowData) {
      if (this.rowData.gebId) {
        this.gebId = this.rowData.gebId;
        this.switchButtonFlag = true;
        this.getEwayBillById();
      } else {
        this.switchButtonFlag = false;
      }
      this.updateButtonsFlag = this.rowData.ewbNo ? true : false;
      this.gebButtonFlag = this.rowData.gebId ? true : false;
      this.eWayBillNum = this.rowData.ewbNo;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    // validateDocNum(docNo) {
    //   const regex = /^([a-zA-Z1-9]{1}[a-zA-Z0-9/-]{0,15})$/;
    // },
    downloadLog() {
      if (!this.reqId) {
        alert('Please print report first');
      } else {
        window.open(this.dowloadReport + '/' + this.reqId, '_blank');
      }
    },
    getValueSetDtls() {
      this.getStateDtls();
      this.getSupplyTypeDtls();
      this.getSubSupplyTypeDtls();
      this.getTrxTypeDtls();
      this.getDocTypeDtls();
      this.getModeDtls();
      this.getVehicleTypeDtls();
      this.getSourceTypeDtls();
      this.getUcqDtls();
    },
    getStateDtls() {
      const payload = {
        country_id: 100
      };
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetStateData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            const itemDetails = result.map(ele => {
              return {
                value: ele.state_tax_code,
                text: ele.state
              };
            });
            this.stateListBillFrom = itemDetails;
            this.stateListBillTo = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUcqDtls() {
      this.$store
        .dispatch('eWayBill/getValueSetUcqData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            const itemDetails = result.map(ele => {
              return {
                value: ele.code,
                text: ele.description
              };
            });
            this.qtyUnitOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    toUpperCaseGstin(type) {
      if (type === 'From') {
        this.gstinBillFrom = this.gstinBillFrom.toUpperCase();
      } else if (type === 'To') {
        this.gstinBillTo = this.gstinBillTo.toUpperCase();
      } else if (type === 'transPortId') {
        this.transporterId = this.transporterId.toUpperCase();
      } else if (type === 'vehicleNum') {
        this.vehicleNum = this.vehicleNum.toUpperCase();
      }
    },
    getSupplyTypeDtls() {
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetSupplyData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.supplyTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubSupplyTypeDtls() {
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetSubSupplyData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.subTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTrxTypeDtls() {
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetTrxTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.trxTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDocTypeDtls() {
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetDocTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.docTypeList = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getModeDtls() {
      // this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetModeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.modeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getVehicleTypeDtls() {
      this.$store
        .dispatch('eWayBill/getValueSetVehicleTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.vehicleTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSourceTypeDtls() {
      this.$store
        .dispatch('eWayBill/getValueSetSourceTypeData')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.sourceTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    autoFilledData(item, type) {
      this.eWayBillItemDtlsData = [
        {
          productName: null,
          productDesc: null,
          hsnCode: null,
          quantity: null,
          qtyUnit: {
            text: null,
            value: null
          },
          cgstRate: null,
          sgstRate: null,
          cessRate: null,
          igstRate: null,
          taxableAmount: null,
          cgst_amt: null,
          sgst_amt: null,
          igst_amt: null,
          cess_amt: null,
          itemLineId: 0
        }
      ];
      try {
        this.selectedSupplyType = {
          value: type === 'Manual' ? item.supplyTypeCode : 'O',
          text: type === 'Manual' ? item.supplyTypeCodeMeaning : 'Outward'
        };
        this.selectedTrxType = {
          value: type === 'Manual' ? item.transectionTypeCode.toString() : '1',
          text: type === 'Manual' ? item.transectionTypeMeaning : '1. Regular'
        };
        this.selectedSubType = {
          value: type === 'Manual' ? item.subSupplyTypeCode : '1',
          text: type === 'Manual' ? item.subSupplyTypeMeaning : 'Supply'
        };
        this.docType = {
          value:
            type === 'Manual'
              ? item.docTypeCode
              : item.taxFlag === 'Y'
              ? 'INV'
              : 'CHL',
          text:
            type === 'Manual'
              ? item.docTypeMeaning
              : item.taxFlag === 'Y'
              ? 'Invoice'
              : 'Challan'
        };
        this.docNum = item.docNo;
        this.docDate = type === 'Manual' ? item.docDate : item.transferDate;
        this.nameBillFrom = item.fromTrdName;
        this.gstinBillFrom = item.fromGstin;
        this.selectedStateBillFrom = {
          value: item.actFromStateCode,
          text: item.fromStateCode
        };
        this.address1DesFrom = item.fromAddr1;
        this.address2DesFrom = item.fromAddr2;
        this.placeDesFrom = item.fromPlace;
        this.pinDesFrom = item.fromPincode;
        this.nameBillTo = item.toTrdName;
        this.gstinBillTo = item.toGstin;
        this.selectedStateBillTo = {
          value: item.actToStateCode,
          text: item.toStateCode
        };
        this.address1ShipTo = item.toAddr1;
        this.address2ShipTo = item.toAddr2;
        this.placeShipTo = item.toPlace;
        this.pinDesTo = item.toPincode;
        this.transporterName = item.transporterName;
        this.transDistance = item.transDistance ? item.transDistance : 0;
        this.transporterId = item.transporterId;
        this.selectedMode = {
          value: item.transModeCode,
          text: item.transModeMeaning
        };
        this.selectedVehicleType = {
          value: item.vehicleTypeCode,
          text: item.vehicleTypeMeaning
        };
        this.vehicleNum = item.vehicleNo;
        this.trnsportDocNum = item.transDocNo;
        this.trnsportDocDate = item.transDocDate;
        this.reqId = item.requestId;
        // this.totalAmount = item.totValue;
        // this.cgstAmount = item.cgstValue;
        // this.sgstAmount = item.sgstValue;
        // this.igstAmount = item.igstValue;
        // this.cessAmount = item.cessValue;
        // this.totalInvValue = item.totInvValue;
        const data = item.itemList.map(detail => {
          return {
            productName: detail.productName,
            productDesc: detail.productDesc,
            hsnCode: detail.hsnCode,
            quantity: type === 'Manual' ? detail.quantity : '1',
            qtyUnit: {
              text: type === 'Manual' ? detail.qtyUnitDescription : 'NOS',
              value: type === 'Manual' ? detail.qtyUnitCode : 'NUMBERS'
            },
            cgstRate: detail.cgstRate,
            sgstRate: detail.sgstRate,
            cessRate: detail.cessRate,
            igstRate: detail.igstRate,
            taxableAmount: detail.taxableAmount,
            cgst_amt: (+detail.taxableAmount * +detail.cgstRate) / 100,
            sgst_amt: (+detail.taxableAmount * +detail.sgstRate) / 100,
            igst_amt: (+detail.taxableAmount * +detail.igstRate) / 100,
            cess_amt: (+detail.taxableAmount * +detail.cessRate) / 100,
            itemLineId: detail.itemLineId ? detail.itemLineId : 0
          };
        });
        this.eWayBillItemDtlsData = data.length
          ? data
          : this.eWayBillItemDtlsData;
        if (item.gebId) {
          // this.filledEwayBillDtls(item);
          this.getEwayBillStatusDtlsByInvNum(item.docNo);
        }
      } catch (err) {
        this.$bvToast.toast(err, {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    filledEwayBillDtls(item) {
      this.ewbDtlsData = [];
      const data = {
        ewb_num: item.ewayBillNo,
        date: item.docDate,
        valid_from: item.ewayBillDate,
        valid_until: item.ewayBillValidDate,
        trans_name: item.transporterName,
        distance: item.transDistance,
        vehicle_num: item.vehicleNo,
        vehicle_type: item.vehicleTypeMeaning,
        remarks: item.remarks,
        status: item.status,
        cancel_status: item.cancel_status,
        cancel_date: item.cancel_date
      };
      this.ewbDtlsData.push(data);
    },
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    saveEwayBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if (!this.editMode) {
        const data = this.eWayBillItemDtlsData.map(ele => {
          return {
            productName: ele.productName,
            productDesc: ele.productDesc,
            hsnCode: +ele.hsnCode,
            quantity: +ele.quantity,
            qtyUnit: ele.qtyUnit.value,
            cgstRate: +ele.cgstRate,
            sgstRate: +ele.sgstRate,
            igstRate: +ele.igstRate,
            cessRate: +ele.cessRate,
            taxableAmount: +ele.taxableAmount,
            cessNonadvol: 0,
            itemLineId: ele.itemLineId ? ele.itemLineId : 0
          };
        });
        const payload = {
          gebId: this.gebId ? this.gebId : 0,
          supplyType: this.selectedSupplyType.value,
          subSupplyType: this.selectedSubType.value,
          subSupplyDesc: '',
          docType: this.docType.value,
          docNo: this.docNum,
          docDate: this.docDate,
          fromGstin: this.gstinBillFrom,
          fromTrdName: this.nameBillFrom,
          fromAddr1: this.address1DesFrom,
          fromAddr2: this.address2DesFrom,
          fromPlace: this.placeDesFrom,
          fromPincode: +this.pinDesFrom,
          actFromStateCode: +this.selectedStateBillFrom.value,
          fromStateCode: +this.selectedStateBillFrom.value,
          toGstin: this.gstinBillTo,
          toTrdName: this.nameBillTo,
          toAddr1: this.address1ShipTo,
          toAddr2: this.address2ShipTo,
          toPlace: this.placeShipTo,
          toPincode: +this.pinDesTo,
          actToStateCode: +this.selectedStateBillTo.value,
          toStateCode: +this.selectedStateBillTo.value,
          transactionType: this.selectedTrxType.value,
          otherValue: '-100',
          totalValue: 0,
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          cessValue: 0,
          cessNonAdvolValue: 0,
          totInvValue: 0,
          transporterId: this.transporterId ? this.transporterId : '',
          transporterName: this.transporterName ? this.transporterName : '',
          transDocNo: this.trnsportDocNum ? this.trnsportDocNum : '',
          transMode: this.selectedMode.value,
          transDistance: this.transDistance,
          transDocDate: this.trnsportDocDate ? this.trnsportDocDate : '',
          vehicleNo: this.vehicleNum,
          vehicleType: this.selectedVehicleType.value,
          itemList: data
        };
        this.loader = true;
        this.$store
          .dispatch('eWayBill/saveEwayBill', payload)
          .then(response => {
            this.editMode = false;
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data;
              this.gebId = result.gebId;
              this.gebButtonFlag = this.gebId ? true : false;
              this.getEwayBillById();
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
        // }
      }
    },
    generateEwayBill() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if (!this.editMode) {
        const data = this.eWayBillItemDtlsData.map(ele => {
          return {
            productName: ele.productName,
            productDesc: ele.productDesc,
            hsnCode: +ele.hsnCode,
            quantity: +ele.quantity,
            qtyUnit: ele.qtyUnit.value,
            cgstRate: +ele.cgstRate,
            sgstRate: +ele.sgstRate,
            igstRate: +ele.igstRate,
            cessRate: +ele.cessRate,
            taxableAmount: +ele.taxableAmount,
            cessNonadvol: 0,
            itemLineId: ele.itemLineId ? ele.itemLineId : 0
          };
        });
        const payloadData = {
          supplyType: this.selectedSupplyType.value,
          subSupplyType: this.selectedSubType.value,
          subSupplyDesc: '',
          docType: this.docType.value,
          docNo: this.docNum,
          docDate: this.docDate,
          fromGstin: this.gstinBillFrom,
          fromTrdName: this.nameBillFrom,
          fromAddr1: this.address1DesFrom,
          fromAddr2: this.address2DesFrom,
          fromPlace: this.placeDesFrom,
          fromPincode: +this.pinDesFrom,
          actFromStateCode: +this.selectedStateBillFrom.value,
          fromStateCode: +this.selectedStateBillFrom.value,
          toGstin: this.gstinBillTo,
          toTrdName: this.nameBillTo,
          toAddr1: this.address1ShipTo,
          toAddr2: this.address2ShipTo,
          toPlace: this.placeShipTo,
          toPincode: +this.pinDesTo,
          actToStateCode: +this.selectedStateBillTo.value,
          toStateCode: +this.selectedStateBillTo.value,
          transactionType: this.selectedTrxType.value,
          otherValue: '-100',
          totalValue: 0,
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          cessValue: 0,
          cessNonAdvolValue: 0,
          totInvValue: 0,
          transporterId: this.transporterId ? this.transporterId : '',
          transporterName: this.transporterName ? this.transporterName : '',
          transDocNo: this.trnsportDocNum ? this.trnsportDocNum : '',
          transMode: this.selectedMode.value,
          transDistance: this.transDistance,
          transDocDate: this.trnsportDocDate ? this.trnsportDocDate : '',
          vehicleNo: this.vehicleNum,
          vehicleType: this.selectedVehicleType.value,
          itemList: data
        };
        const payload = {
          data: payloadData,
          gebId: this.gebId
        };
        this.loader = true;
        this.$store
          .dispatch('eWayBill/generateEwayBill', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const result = JSON.parse(response.data.data);
              this.updateButtonsFlag = result.ewayBillNo ? true : false;
              this.eWayBillNum = result.ewayBillNo;
              if (result.ewayBillDate) {
                this.extendCancelValidation(result.ewayBillDate, 'cancel');
              }
              if (result.validUpto) {
                this.extendCancelValidation(result.validUpto, 'extend');
              }
              this.getEwayBillById();
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              alert(response.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
          });
        // }
      }
    },
    getEwayBillById() {
      const payload = {
        gebId: this.gebId
      };
      this.$store
        .dispatch('eWayBill/getEwayBillById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.updateButtonsFlag = result.ewayBillNo ? true : false;
            this.ewayBillExpiryDate = result.ewayBillValidDate;
            this.updateButtonsFlag = result.ewayBillNo ? true : false;
            this.gebButtonFlag = result.gebId ? true : false;
            this.eWayBillNum = result.ewayBillNo ? result.ewayBillNo : null;
            this.autoFilledData(result, 'Manual');
            if (result.ewayBillDate) {
              // this.extendCancelValidation('28/06/2024 04:14:00 PM', 'cancel');
              this.extendCancelValidation(result.ewayBillDate, 'cancel');
            }
            if (result.ewayBillValidDate) {
              this.extendCancelValidation(result.ewayBillValidDate, 'extend');
              // this.extendCancelValidation('04/07/2024 04:14:00 PM', 'extend');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      //   if (this.editMode) {
      this.eWayBillItemDtlsData.push({
        productName: null,
        productDesc: null,
        hsnCode: null,
        quantity: null,
        qtyUnit: {
          text: null,
          value: null
        },
        // taxable_value: null,
        cgstRate: null,
        sgstRate: null,
        cessRate: null,
        igstRate: null,
        taxableAmount: null,
        cgst_amt: null,
        sgst_amt: null,
        igst_amt: null,
        cess_amt: null,
        total_inv_value: null,
        itemLineId: 0
      });
      //   }
    },
    removeRow(index) {
      this.eWayBillItemDtlsData.splice(index, 1);
    },
    calculateTax(item, index, type) {
      if (item.taxableAmount) {
        let calTax = 0;
        if (type === 'cgst') {
          this.cgstSgstFlag = item.cgstRate ? true : false;
          calTax = (+item.taxableAmount * +item.cgstRate) / 100;
          item.sgstRate = item.cgstRate;
          item.igstRate = 0;
          item.igst_amt = 0;
          this.eWayBillItemDtlsData[index].cgst_amt = calTax;
          this.eWayBillItemDtlsData[index].sgst_amt = calTax;
          this.eWayBillItemDtlsData = JSON.parse(
            JSON.stringify(this.eWayBillItemDtlsData)
          );
        } else if (type === 'sgst') {
          this.sgstSgstFlag = item.cgstRate ? true : false;
          calTax = (+item.taxableAmount * +item.sgstRate) / 100;
          item.cgstRate = item.sgstRate;
          item.igstRate = 0;
          item.igst_amt = 0;
          this.eWayBillItemDtlsData[index].cgst_amt = calTax;
          this.eWayBillItemDtlsData[index].sgst_amt = calTax;
          this.eWayBillItemDtlsData = JSON.parse(
            JSON.stringify(this.eWayBillItemDtlsData)
          );
        } else if (type === 'igst') {
          this.igstFlag = item.igstRate ? true : false;
          calTax = (+item.taxableAmount * +item.igstRate) / 100;
          item.cgstRate = 0;
          item.cgst_amt = 0;
          item.sgst_amt = 0;
          item.sgstRate = 0;
          item.igst_amt = calTax;
          this.eWayBillItemDtlsData = JSON.parse(
            JSON.stringify(this.eWayBillItemDtlsData)
          );
        } else if (type === 'cess') {
          calTax = (+item.taxableAmount * +item.cessRate) / 100;
          item.cess_amt = calTax;
          this.eWayBillItemDtlsData = JSON.parse(
            JSON.stringify(this.eWayBillItemDtlsData)
          );
        }
      } else {
        item.cgstRate = null;
        item.sgstRate = null;
        item.igstRate = null;
        item.cessRate = null;
        this.$bvToast.toast('Please enter taxable amount first.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    validateHsnCode(item) {
      if (item.hsnCode.length >= 4) {
        // const regex = /^[0-3][0-9]\/[0-1][0-9]\/[2][0][1-2][0-9]$/;
        // if (hsnCode && !regex.test(hsnCode)) {
        //   this.$bvToast.toast('Invalid HSN code format.', {
        //     title: 'Alert',
        //     variant: 'danger',
        //     solid: true
        //   });
        // }
      } else {
        item.hsnCode = null;
        this.$bvToast.toast('HSN Code Should between 4 to 8 digits.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    validateGstn(gstn) {
      if (gstn.length < 15) {
        this.$bvToast.toast('The GSTIN must be 15 digits.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    showHideUpdateEwayBillModal(flag, type) {
      this.updateProps = {};
      this.updateFormType = type;
      if (type === 'partB') {
        // if (this.updatePartBFlag) {
        this.updateProps.formType = type;
        this.updateProps.ewbNo = this.eWayBillNum;
        this.updateProps.gebId = this.gebId;
        this.updateProps.mode = this.modeOptions;
        this.updateProps.selectedMode = {
          text: this.selectedMode.text,
          value: this.selectedMode.value
        };
        this.updateProps.vehicleType = this.vehicleTypeOptions;
        this.updateProps.vehicleNum = this.vehicleNum;
        this.updateProps.trnsDocNo = this.trnsportDocNum;
        this.updateProps.trnsDocDate = this.trnsportDocDate;
        this.updateProps.fromPlace = this.placeDesFrom;
        this.updateProps.fromState = {
          text: this.selectedStateBillFrom.text,
          value: this.selectedStateBillFrom.value
        };
        this.updateProps.fromStateOptions = this.stateListBillFrom;
        this.updateProps.selectedVehicleType = {
          text: this.selectedVehicleType.text,
          value: this.selectedVehicleType.value
        };
        // this.showUpdateEwayBillModal = flag;
        // } else {
        //   // E-way bill validity period has expired.
        //   this.$bvToast.toast(
        //     'The validity period of the E-way bill has expired. Part-B can be updated only if the E-way bill is within the validity period.',
        //     {
        //       title: 'Alert',
        //       variant: 'danger',
        //       solid: true
        //     }
        //   );
        // }
        if (this.ewayBillExpiryDate !== 'null') {
          if (this.updatePartBFlag) {
            this.showUpdateEwayBillModal = flag;
          } else {
            // E-way bill validity period has expired.
            this.$bvToast.toast(
              'The validity period of the E-way bill has expired. Part-B can be updated only if the E-way bill is within the validity period.',
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          }
        } else {
          this.showUpdateEwayBillModal = flag;
        }
      } else if (type === 'transporterId') {
        // if (this.updatePartBFlag) {
        this.updateProps.formType = type;
        this.updateProps.ewbNo = this.eWayBillNum;
        this.updateProps.trnsId = this.transporterId;
        //   this.showUpdateEwayBillModal = flag;
        // } else {
        //   this.$bvToast.toast(
        //     'The validity period of the E-way bill has expired. Transporter can be updated only if the E-way bill is within the validity period.',
        //     {
        //       title: 'Alert',
        //       variant: 'danger',
        //       solid: true
        //     }
        //   );
        // }
        if (this.ewayBillExpiryDate !== 'null') {
          if (this.updatePartBFlag) {
            this.showUpdateEwayBillModal = flag;
          } else {
            // E-way bill validity period has expired.
            this.$bvToast.toast(
              'The validity period of the E-way bill has expired. Transporter Id can be updated only if the E-way bill is within the validity period.',
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          }
        } else {
          this.showUpdateEwayBillModal = flag;
        }
      } else if (type === 'canEwaybill') {
        if (this.cancelEwayBillFlag) {
          this.updateProps.formType = type;
          this.updateProps.ewbNo = this.eWayBillNum;
          this.updateProps.gebId = this.gebId;
          this.showUpdateEwayBillModal = flag;
        } else {
          this.$bvToast.toast(
            'E-way bill can only be cancelled within 24 hours of generation of E-way bill.',
            {
              title: 'Alert',
              variant: 'danger',
              solid: true
            }
          );
        }
      } else if (type === 'extEwaybill') {
        if (this.extendEwayBillFlag) {
          this.updateProps.formType = type;
          this.updateProps.ewbNo = this.eWayBillNum;
          this.updateProps.transDistance = this.transDistance;
          this.updateProps.vehicleNo = this.vehicleNum;
          this.updateProps.vehicleType = this.vehicleTypeOptions;
          this.updateProps.fromPlace = this.placeDesFrom;
          this.updateProps.fromAddr1 = this.address1DesFrom;
          this.updateProps.fromAddr2 = this.address2DesFrom;
          this.updateProps.fromState = {
            text: this.selectedStateBillFrom.text,
            value: this.selectedStateBillFrom.value
          };
          this.updateProps.fromStateOptions = this.stateListBillFrom;
          this.updateProps.trnsDocNo = this.trnsportDocNum;
          this.updateProps.trnsDocDate = this.trnsportDocDate;
          this.updateProps.mode = this.modeOptions;
          this.updateProps.selectedMode = {
            text: this.selectedMode.text,
            value: this.selectedMode.value
          };
          this.updateProps.fromPincode = this.pinDesFrom;
          this.updateProps.selectedVehicleType = {
            text: this.selectedVehicleType.text,
            value: this.selectedVehicleType.value
          };
          this.showUpdateEwayBillModal = flag;
        } else {
          if (!this.updatePartBFlag) {
            this.$bvToast.toast(
              `The validity period of the E-way bill has expired. The validity of the EWB can be extended between 8 hours before the expiry time and 8 hours after the expiry time.`,
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          } else {
            this.$bvToast.toast(
              `The validity of E-way bill can be extended between 8 hours before ${this.ewayBillExpiryDate} and 8 hours after ${this.ewayBillExpiryDate}.`,
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          }
        }
      }
    },
    extendCancelValidation(givenDateTime, type) {
      const [date, time, period] = givenDateTime.split(' ');
      const [day, month, year] = date.split('/');
      const [hours, minutes, seconds] = time.split(':');

      let givenDate = new Date(
        `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
      );
      if (period === 'PM' && hours !== '12') {
        givenDate.setHours(givenDate.getHours() + 12);
      } else if (period === 'AM' && hours === '12') {
        givenDate.setHours(0);
      }
      const now = new Date();
      // const istOffset = 5.5 * 60 * 60 * 1000;
      const currentTime = new Date(now.getTime());
      if (type === 'extend') {
        const beforeTime = new Date(givenDate.getTime() - 8 * 60 * 60 * 1000);
        const afterTime = new Date(givenDate.getTime() + 8 * 60 * 60 * 1000);
        if (currentTime >= beforeTime && currentTime <= afterTime) {
          this.extendEwayBillFlag = true;
        }
        if (currentTime <= givenDate) {
          this.updatePartBFlag = true;
        }
      }
      if (type === 'cancel') {
        const beforeTime = new Date(givenDate.getTime() - 0 * 60 * 60 * 1000);
        const afterTime = new Date(givenDate.getTime() + 24 * 60 * 60 * 1000);
        if (currentTime >= beforeTime && currentTime <= afterTime) {
          this.cancelEwayBillFlag = true;
        }
      }
    },
    toggle() {
      this.ewbManualErpFlag != this.ewbManualErpFlag;
    },
    getInvoiceNumber(flag) {
      if (this.sourceType.text) {
        this.showHideInvDtlsModal(flag);
      } else {
        this.$bvToast.toast('Please select Source Type first', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    showHideInvDtlsModal(flag) {
      this.showInvDtlsModal = flag;
    },
    mapInvDtls(item) {
      this.switchButtonFlag = item.gebId ? true : false;
      this.gebButtonFlag = item.gebId ? true : false;
      this.invoiceNumber = item.num;
      this.showInvDtlsModal = false;
      if (item.gebId) {
        this.gebId = item.gebId;
        this.getEwayBillById();
        this.getEwayBillStatusDtlsByInvNum(item.num);
      } else if (item.num) {
        this.getEwayBillDtlsByInvId(item.id);
      }
    },
    getEwayBillDtlsByInvId(invId) {
      const payload = {
        invoice_id: invId
      };
      this.$store
        .dispatch('eWayBill/getEwayBillDtlsByInvId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.autoFilledData(result, 'ERP');
            this.gebButtonFlag = result.gebId ? true : false;
            this.erpFlag = true;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEwayBillStatusDtlsByInvNum(invNum) {
      const payload = {
        docNum: invNum
      };
      this.$store
        .dispatch('eWayBill/getEwayBillStatusDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.ewbDtlsData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedEwbDtlsRow(item) {
      this.gebId = item.gebId;
      if (this.gebId) {
        this.getEwayBillById();
      }
    },
    clearValues(value) {
      if (value === this.invoiceNumber) {
        this.invoiceNumber = null;
      }
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.selectedSupplyType.text) {
        this.selectedSupplyType.text = null;
        this.selectedSupplyType.value = null;
        this.getSupplyTypeDtls();
      } else if (vsetCode === this.selectedSubType.text) {
        this.selectedSubType.text = null;
        this.selectedSubType.value = null;
        this.getSubSupplyTypeDtls();
      } else if (vsetCode === this.docType.text) {
        this.docType.text = null;
        this.docType.value = null;
        this.getDocTypeDtls();
      } else if (vsetCode === this.docNum) {
        this.docNum = null;
      } else if (vsetCode === this.selectedTrxType.text) {
        this.selectedTrxType.text = null;
        this.selectedTrxType.value = null;
        this.getTrxTypeDtls();
      } else if (vsetCode === this.selectedStateBillFrom.text) {
        this.selectedStateBillFrom.text = null;
        this.selectedStateBillFrom.value = null;
        this.getStateDtls();
      } else if (vsetCode === this.selectedStateBillTo.text) {
        this.selectedStateBillTo.text = null;
        this.selectedStateBillTo.value = null;
        this.getStateDtls();
      } else if (vsetCode === this.selectedMode.text) {
        this.selectedMode.text = null;
        this.selectedMode.value = null;
        this.getModeDtls();
      } else if (vsetCode === this.selectedVehicleType.text) {
        this.selectedVehicleType.text = null;
        this.selectedVehicleType.value = null;
        this.getVehicleTypeDtls();
      } else if (vsetCode === this.eWayBillItemDtlsData[index].qtyUnit.text) {
        this.eWayBillItemDtlsData[index].qtyUnit.text = null;
        this.eWayBillItemDtlsData[index].qtyUnit.value = null;
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.EWB_UNIT) {
        this.eWayBillItemDtlsData[this.specIndex].qtyUnit.text =
          item.value_meaning;
        this.eWayBillItemDtlsData[this.specIndex].qtyUnit.value =
          item.value_code;
      }
      this.eWayBillItemDtlsData = JSON.parse(
        JSON.stringify(this.eWayBillItemDtlsData)
      );
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    printReport() {
      const payload = {
        ewbNo: this.eWayBillNum
      };
      this.loader = true;
      this.$store
        .dispatch('eWayBill/ewayBillReportPrint', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.getEwayBillById();
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          } else {
            this.$bvToast.toast(response.data.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(error => {
          this.loader = false;
          this.responseMsg = error;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
